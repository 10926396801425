import { MapWithDefault } from '@app-shared/helpers/helpers';

/**
 * An enum with (mostly) 4 digit language codes that correlate to 
 * our language file names.
 * 
  https://learn.microsoft.com/en-us/openspecs/office_standards/ms-oe376/6c085406-a698-4e12-9d4d-c3b0ee3dbc4a
*/
export enum LanguageCode {
  ENGLISH_USA = 'en-US',
  GERMAN_GERMANY = 'de-DE',
  CZECH = 'cs-CZ',
  ENGLISH_CANADA = 'en-CA',
  ENGLISH_GREAT_BRITAIN = 'en-GB',
  SPANISH_SPAIN = 'es-ES',
  SPANISH_MEXICO = 'es-MX',
  FINNISH = 'fi-FI',
  FRENCH_CANADA = 'fr-CA',
  FRENCH_FRANCE = 'fr-FR',
  ITALIAN_ITALY = 'it-IT',
  JAPANESE = 'ja-JP',
  DUTCH_NETHERLANDS = 'nl-NL',
  NORWEGIAN = 'no', // norwegian is complicated so i will leave it as "no" for now
  POLISH = 'pl-PL',
  PORTUGUESE_PORTUGAL = 'pt-PT',
  RUSSIAN = 'ru-RU',
  UKRAINIAN = 'uk-UA',
}

export type LanguageCodes = `${LanguageCode}`;

/**
 * A map that returns a language code that we have, given the
 * short code of a language.
 *
 * Default value: `en-US`
 *
 * Examples:
 * ```
 *  'fr' --> 'fr-FR'
 *  'en' --> 'en-US'
 * ```
 */
export const shortCodeLanguageToLanguageCodeMap = new MapWithDefault<string, LanguageCodes>(LanguageCode.ENGLISH_USA, [
  ['en', LanguageCode.ENGLISH_USA],
  ['fr', LanguageCode.FRENCH_FRANCE],
  ['cs', LanguageCode.CZECH],
  ['fi', LanguageCode.FINNISH],
  ['es', LanguageCode.SPANISH_SPAIN],
  ['ja', LanguageCode.JAPANESE],
  ['nl', LanguageCode.DUTCH_NETHERLANDS],
  ['pl', LanguageCode.POLISH],
  ['pt', LanguageCode.PORTUGUESE_PORTUGAL],
  ['ru', LanguageCode.RUSSIAN],
  ['uk', LanguageCode.UKRAINIAN],
  ['it', LanguageCode.ITALIAN_ITALY],
  ['de', LanguageCode.GERMAN_GERMANY],
]);
